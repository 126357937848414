import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import {
  OneKeyBanner as SharedUIOneKeyBanner,
  PageLocation,
  LineOfBusinessDomain,
} from "@shared-ui/retail-loyalty-one-key-banner";
import { OneKeyBannerProps, OneKeyBannerFlexModuleResult } from "./typings";

export const OneKeyBanner = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: OneKeyBannerProps) => {
    const { templateComponent, flexModuleModelStore } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as OneKeyBannerFlexModuleResult | null;

    if (!model || !model.pageLocation || !model.lineOfBusiness) {
      return null;
    }

    const { pageLocation, lineOfBusiness, useLoyaltyCurrency, showBorder } = model;

    const oneKeyBannerInput = {
      useLoyaltyCurrency,
      // @ts-ignore
      pageLocation: PageLocation[pageLocation],
      // @ts-ignore
      lineOfBusiness: LineOfBusinessDomain[lineOfBusiness],
      packageType: null,
      packageLOB: null,
    };

    return (
      <div data-testid={id} id={id}>
        <SharedUIOneKeyBanner inputs={oneKeyBannerInput} showBorder={showBorder} />
      </div>
    );
  })
);

export default OneKeyBanner;
